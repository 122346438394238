import React from 'react'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'

import {Answers} from '../components/answers'
import {Breadcrumbs} from '../components/breadcrumbs'
import {Content} from '../components/content'
import {Layout} from '../components/grid'
import {Header} from '../components/header'

interface PageData{
  answer: {
    title: string
    body: string
    fields: {
      slug: string
      breadcrumbs: string
    }
  }
  question: {
    answers: {
      title: string
      fields: {
        slug: string
      }
    }[]
  }
}

const AnswerTemplate: React.FC<{data: PageData}> = ({data}) => {
  const {answer, question} = data

  return <>
    <Helmet title={answer.title} />
    <Header />
    <Layout>
      <Breadcrumbs breadcrumbs={answer.fields.breadcrumbs} />
      <h1>{answer.title}</h1>
      <Content html={answer.body} />
      <h2>Try Something else</h2>
      <p>Is this not the answer your looking for? Try one of these:</p>
      <Answers answers={question.answers} />
      <h2>Still Stuck</h2>
      <p>If your still experiancing problems <a href="https://support.ed-itsolutions.com">open a ticket</a> and your Ed-IT Solutions engineer will get back to you.</p>
    </Layout>
  </>
}

export default AnswerTemplate

export const PageQuery = graphql`
  query AnswerTemplateQuery($slug: String, $questionSlug: String){
    answer: directusAnswer(fields: {slug: {eq: $slug}}) {
      title
      body
      fields{
        slug
        breadcrumbs
      }
    }

    question: directusQuestion(fields: {slug: {eq: $questionSlug}}) {
      answers {
        title
        fields{
          slug
        }
      }
    }
  }
`